"use client"
import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material"
import { Page } from "@tc/ui-shared/components"

export default function Error({ reset }) {
  const onClick = () => {
    reset()
  }
  return (
    <Page
      sx={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container fixed sx={{ width: "580px" }}>
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          divider={<Divider flexItem orientation="vertical" />}
          sx={{
            mt: -5,
            mb: 4,
          }}
        >
          <Typography variant="h5" component={"strong"}>
            Error 500
          </Typography>
          <Typography variant="h6" sx={{ width: "380px" }}>
            An error occurred on the server. Please contact the administrator
          </Typography>
        </Stack>

        <Box sx={{ textAlign: "center" }}>
          <Button variant="contained" onClick={onClick}>
            Go Back
          </Button>
        </Box>
      </Container>
    </Page>
  )
}
